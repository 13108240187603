.sidebar {
    position: fixed;
    top: 25%;
    right: 10px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    z-index: 5;
    transform: translate(130px,0);
}
.icon {
    font-size: 30px;
    margin-right: 20px;
}
.active{
    text-decoration: none;
    color:red;
}
.text {
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider {
    width: 180px;
    display: flex;    
    justify-content:start;
    align-items:center;
    padding: 7.5%;
    margin: 5%;
    transition: 0.3s ease-in;
    
}
.slider:hover{
    transform: translate(-115px,0);
}

.inactive {
    text-decoration: none;
    color: white;
}



@media screen and (max-width: 1100px) {
    .sidebar {
        display: none;
    }
}