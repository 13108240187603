.vantajs {
    position: relative;
    width: 100%;
    height: 100vh;
}

.sponsor-page-logo {
    position: absolute;
    z-index: 1;
    top: 3vh;
    left: 2vw;
    width: 350px;
    height: 120px;
}

.animated-bg {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#bgs {
    position: relative;
    height: 100vh;
}



.vanta-canvas {
    height: 100vh;
    width: 100%;
}

@media only screen and (max-width: 540px) {
    .sponsor-page-logo {
        top: 26px;
        height: 90px;
        width: 280px;
    }
}

@media only screen and (min-width: 320px) {
    .sponsor-page-logo {
        top: 4vh;
        height: 80px;
        width: 235px;
    }
} 


.title {
    border-radius: 0 50px 0 50px;
    position: absolute;
    top: 35vh;
    max-width: 800px;
    z-index: 1;
    font-family: Arial, Helvetica, sans-serif;
    padding: 50px;
	background-color: rgba(0, 0, 0, 0.75); /* Black w/opacity/see-through */
}
.title_head{
    text-transform: uppercase;
    color: red;
}
.title_desc {
    margin-top: 20px;
    font-size: 20px;
}

