.slide_details {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
	color: white;
	border: 3px solid black;
	
	padding: 20px;
    padding-left: 10vw;
    padding-right: 10vw;
}
.slide {
	display: flex;
    flex-direction: column;
	align-items: center;
	justify-content: flex-end;
}

.slide_heading {
	font-size: 50px;
	text-transform: uppercase;
}
.slide_description {
	font-weight: 100;
	font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
		"Lucida Sans Unicode", Geneva, Verdana, sans-serif;
	margin: 10px;
}

@media screen and (max-width: 1200px) {
	.slide {
		height: 60vh;
		
	}
}

@media screen and (max-width: 1000px) {
	.slide_heading{
        font-size: 25px;
    }
}



