/* Container Styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  background-color: #000; /* Black background */
}

.container .heading {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-decoration: underline;
  font-size: 3.5rem;
  color: red;
  text-align: center;
  font-weight: bold;
}

/* Main Card Section */
.tlog {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
  gap: 4rem; /* Spacing between cards */
}

/* Individual Card */
.tlogcard {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-bottom: 4rem;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  width: 100%;
  max-width: 1000px;
}

/* Image Styling */
.tlogimg {
  width: 350px; 
  height: 350px; /* Maintain aspect ratio */
}

/* Card Text Styling */
.tlogtext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: white; /* White text color */
}

.tlogcard h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 1rem;
  color: white; 
}

.tlogtext p {
  font-size: 1.8rem;
  color: white; /* White text for quotes */
  margin: 0.5rem 0;
  line-height: 1.5;
}

/* Links Styling */
.tlogtext a {
  color: red; /* Red links */
  text-decoration: underline;
  font-size: 1.6rem;
  font-weight: bold;
  transition: color 0.3s;
}

.tlogtext a:hover {
  color: white; /* White on hover */
}

/* Image Card Hover Effect */
.imgcard {
  position: relative;
  width: 350px;
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
}

.imgcard a {
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  transition: width 1s ease;
  backdrop-filter: (2px);/* Semi-transparent black */
}

.imgcard:hover a {
  width: 100%;
}

/* Responsive Styling */
@media (max-width: 940px) {
  .imgcard {
    width: 250px;
    height: 250px;
  }

  .tlogcard {
    flex-direction: column;
    gap: 2rem;
  }

  .tlogcard h1 {
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .tlog {
    gap: 2rem;
  }

  .tlogcard {
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
  }

  .tlogcard h1 {
    font-size: 1.8rem;
  }

  .tlogtext p {
    font-size: 1.25rem;
  }

  .tlogtext a {
    font-size: 1.4rem;
  }
}
