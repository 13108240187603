/* Marquee Styling */
 .marquee {
	width: 90vw;
	height: fit-content;
	padding: 10px;
	background-color: black;
	overflow-x: hidden;
	position: relative;
}

.marquee-content {
	list-style: none;
	height: 100%;
	display: flex;
	/*animation: scrolling 20s linear infinite;*/
	color: red;
	flex-direction: row;
    	flex-wrap: wrap;
}


.marquee-content li {
	width: 26vw;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5rem;
	white-space: nowrap;
	margin-bottom: 3rem;
	justify-content: flex-end;
	margin: auto;
}

.marquee::before,
.marquee::after {
	position: absolute;
	top: 0;
	width: 10vw;
	bottom: 0;
	content: "";
	z-index: 1;
}

.marquee::before {
	left: -0.5vw;
	background: linear-gradient(to right, rgb(0, 0, 0) 0%, transparent 100%);
}

.marquee::after {
	right: -0.5vw;
	background: linear-gradient(to left, rgb(0, 0, 0) 0%, transparent 100%);
}

.marquee-content:hover {
	animation-play-state: paused;
} */
/*
.marquee_name {
	position: absolute;
	line-height: 6vw;
	width: 20%;
	font-size: xx-large;
	font-weight: 700;
	right: 20%;
	text-align: center;
	background-color: white;
	color: black;
	height: 6vw;
	transform: rotate(90deg);
	z-index: 1;
}*/

/* .cards {
	position: relative;
	width: 80%;
	height: 100%;
	background-color: black;
	padding: 8px 12px;
	margin: 10px;
	z-index: 1;
}

.cards::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	background: linear-gradient(red, transparent, white);
	z-index: -1;
	transform: rotate(3deg);
	transition: all 1s;
}

.cards::after {
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-radius: 20px;
	background: black;
	z-index: -1;
	transition: all 1s;
}

.cards:hover::before {
	transform: rotate(0deg);
}

.cards:hover::after {
	top: 1px;
	bottom: 1px;
	right: 1px;
	left: 1px;
}

@media screen and (max-width: 900px) {
	.marquee::before,
	.marquee::after {
		width: 5vw;
	}

	.marquee-content li {
		width: 40vw;
	}

	@keyframes scrolling {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(-480vw);
		}
	}

	.marquee {
		width: 95vw;
	}
}

.pic {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 8px 12px;
	width: fit-content;
}

.pic img {
	height: 100%;
	width: 100%;
}

@keyframes scrolling {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-240vw);
	}
}

@media screen and (max-width: 700px) {
	.marquee-content {
		animation: none;
		height: fit-content;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	
	.marquee{
	background-color: transparent;
	width: 100vw;	
	}
	.marquee::after {
		display: none;
	}
	.marquee::before {
		display: none;
	}
	.marquee-content > li {
		margin: 10px;
	}
	.cards {
		width: 100%;
	}
}

@media screen and (max-width: 700px) {
	.marquee-content > li {
		width: 60vw;
	}
} */



/* Marquee Styling */
.marquee {
	width: 90vw;
	height: fit-content;
	padding: 10px;
	background-color: black;
	overflow-x: hidden;
	position: relative;
}

.marquee-content {
	list-style: none;
	height: 100%;
	display: flex;
	/*animation: scrolling 20s linear infinite;*/
	color: red;
	flex-direction: row;
    	flex-wrap: wrap;
}


.marquee-content li {
	width: 26vw;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 5rem;
	white-space: nowrap;
	margin-bottom: 3rem;
	justify-content: flex-end;
	margin: auto;
}

.marquee::before,
.marquee::after {
	position: absolute;
	top: 0;
	width: 10vw;
	bottom: 0;
	content: "";
	z-index: 1;
}

.marquee::before {
	left: -0.5vw;
	background: linear-gradient(to right, rgb(0, 0, 0) 0%, transparent 100%);
}

.marquee::after {
	right: -0.5vw;
	background: linear-gradient(to left, rgb(0, 0, 0) 0%, transparent 100%);
}

.marquee-content:hover {
	animation-play-state: paused;
}/*
.marquee_name {
	position: absolute;
	line-height: 6vw;
	width: 20%;
	font-size: xx-large;
	font-weight: 700;
	right: 20%;
	text-align: center;
	background-color: white;
	color: black;
	height: 6vw;
	transform: rotate(90deg);
	z-index: 1;
}*/

.cards {
	position: relative;
	width: 80%;
	height: 100%;
	background-color: black;
	padding: 8px 12px;
	margin: 10px;
	z-index: 1;
}

.cards::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 20px;
	width: 100%;
	height: 100%;
	background: linear-gradient(red, transparent, white);
	z-index: -1;
	transform: rotate(3deg);
	transition: all 1s;
}

.cards::after {
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	bottom: 0px;
	right: 0px;
	border-radius: 20px;
	background: black;
	z-index: -1;
	transition: all 1s;
}

.cards:hover::before {
	transform: rotate(0deg);
}

.cards:hover::after {
	top: 1px;
	bottom: 1px;
	right: 1px;
	left: 1px;
}

@media screen and (max-width: 900px) {
	.marquee::before,
	.marquee::after {
		width: 5vw;
	}

	.marquee-content li {
		width: 40vw;
	}

	@keyframes scrolling {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(-480vw);
		}
	}

	.marquee {
		width: 95vw;
	}
}

.pic {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 8px 12px;
	width: fit-content;
}

.pic img {
	height: 100%;
	width: 100%;
}

@keyframes scrolling {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-240vw);
	}
}

@media screen and (max-width: 700px) {
	.marquee-content {
		animation: none;
		height: fit-content;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
	
	.marquee{
	background-color: transparent;
	width: 100vw;	
	}
	.marquee::after {
		display: none;
	}
	.marquee::before {
		display: none;
	}
	.marquee-content > li {
		margin: 10px;
	}
	.cards {
		width: 100%;
	}
}

@media screen and (max-width: 700px) {
	.marquee-content > li {
		width: 60vw;
	}
}