.spons-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px;
    /* width: 300px;
  height: 400px; */
    overflow:hidden;
  }


.spons{
    position: relative;
    width: 18rem;
    height: 18rem;
    /* width: 95%;
    height: 95%; */
    background: white;
    border-radius: 20px;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
    overflow: hidden;
    padding: 30px 80px;
    margin:70px 50px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
}


.spons::before {
	content: "";
	position: absolute;
	/* top: 0;
	left: 0; */
	border-radius: 20px;
	width: 40%;
	height: 150%;
  z-index: -5;
  background: -webkit-linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
  background: linear-gradient(to right, #FF0080, #FF8C00, #40E0D0);
    animation: glowing01 5s linear infinite;
    transform-origin: center;
    animation: glowing 5s linear infinite;
}


.spons--image {
    
    height:17rem;
    width:17rem;
}

  @keyframes glowing {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }