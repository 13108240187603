img {
	border-radius: 5px;
	height: auto;
	width: 10rem;
}

div {
	text-align: center;
}

small {
	display: block;
}

a {
	color: #343078;
	text-decoration: none;
}

@media screen and (min-width: 1099px) {
	.ham {
		display: none;
	}
}
