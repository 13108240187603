.landing-page {
    height: 100%;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

#title {
    color: #e62b1e;
    position: absolute;
    width: 100%;
    top: 45vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
}

.landing-page img.sponsorpage-logo {
    position: absolute;
    z-index: 1;
    top: 3vh;
    left: 2vw;
    width: 350px;
    height: 120px;
}

.outer_box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 20px 30px;
}

@media only screen and (max-width: 540px) {}

.landing-page img {
    top: 4vh;
    height: 80px;
    width: 235px;
}

.spons_heading {
    font-size: 30px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
}

.spons_heading > span {
    color: #e62b1e;
}

.spons-title {
    background-color: rgba(0, 0, 0, 0.35);
    border-radius: 0 50px 0 50px;
    max-width: 800px;
    margin: 40px auto;
    /* top: 35vh; */
    z-index: 1;
    font-family: Arial, Helvetica, sans-serif;
    padding: 50px;
}

.spons-title_head {
    text-transform: uppercase;
    color: red;
}

.spons-title_desc {
    margin-top: 20px;
    line-height: 20px;
    font-size: 20px;
}

@media screen and (max-width: 730px) {
    .spons-title {
        transform: scale(0.7);
    }
}

/* Platinum Sponsor Section */
.platinum-partner-section {
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    flex-direction: column;
    z-index: 100;
    align-items: center;
    justify-content: center;
    margin: auto 0;
    font-family: Arial, Helvetica, sans-serif;
    padding: 50px;
    border-radius: 20px;
}

.platinum-partner-section h2 {
    font-size: 2rem;
    font-weight: bold;
    color: red;
    margin-bottom: 20px;
}

.platinum-partner-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.platinum-partner-image:hover {
    transform: scale(1.05);
}

/* Responsive styles for Title Sponsor */
@media (max-width: 1024px) {
    .platinum-partner-section h2 {
        font-size: 2rem;
    }

    .platinum-partner-image {
        max-width: 300px;
    }
}

@media (max-width: 768px) {
    .platinum-partner-section h2 {
        font-size: 1.8rem;
    }

    .platinum-partner-image {
        max-width: 250px;
    }
}

/* Outreach Partner Section */
.outreach-partner-section {
    width: 500px;
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    flex-direction: column;
    z-index: 100;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    font-family: Arial, Helvetica, sans-serif;
    padding: 25px 20px;
    border-radius: 20px;
}

.outreach-partner-section h4 {
    font-size: 1.5rem;
    font-weight: bold;
    color: red;
    margin-bottom: 20px;
}

.outreach-partner-image {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.outreach-partner-image:hover {
    transform: scale(1.05);
}

/* Responsive styles for Title Sponsor */
@media (max-width: 1024px) {
    .outreach-partner-section h2 {
        font-size: 2rem;
    }

    .outreach-partner-image {
        max-width: 300px;
    }
}

@media (max-width: 768px) {
    .outreach-partner-section h2 {
        font-size: 1.8rem;
    }

    .outreach-partner-image {
        max-width: 250px;
    }
}
