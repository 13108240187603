/* body{
    width: 100%;
    height:100vh;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}  */
.card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
  }


.card{
    width: calc(33.33% - 20px);/* Adjust the card width as needed*/
  margin-bottom: 50px;/* Add vertical spacing between rows*/
    height: 375px;
    width:375px;
    border-radius: 15px;
    border: 8px solid #fff;
    position: relative;
    overflow: hidden;
    perspective: 1000px; /* Create a 3D perspective */
    transition: transform 1s ease-in-out;
}

.speaker--image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    transform: scale(1);
    transition: 1s ease-in-out;
}
.card-body{
    width: 100%;
    height: 100%;
    top: 0;
    right:-100%;
    position: absolute;
    background: #1f3d4738;
    backdrop-filter: blur(2px);
    border-radius: 15px;
    color: white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 1.5s;
}
.card:hover {
    transform: rotateY(10deg) rotateX(10deg) scale(1.15);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}
.card:hover .card-body{
    right: 0;
}
.card:hover .speaker--image{
   transform: scale(1.5);
 transform-origin: center top;
}

.slideLink{
    display: inline-block;
    /* width:20px; */

  }
 
  .fa , .in, .yo, .li , .tw {
    height: 30px;
    width: 40px;
    margin: 5px;
    color:white;
  }  

