.cardGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 50px;
}

.cardd {
  transform-style: preserve-3d;
  position: relative;
  padding: 30px 50px;
  width: 350px;
  height: 400px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(25deg,
      rgb(27, 12, 49) 0%,
      rgb(212, 41, 41) 86%);
  transition: all 0.5s ease;
  margin: 80px 30px;
  cursor: pointer;

}

.cardd:hover {
  height: 500px;
  /* scale: 1.2; */
}

/* width: 300px;
  height: 200px; */
.imgbox {
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateY(-80px);
  z-index: 1;
}



.speaker-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
}

.content {
  padding: 10px 0px;
  text-align: center;
  transform: translateY(-300px);
  opacity: 0;
  transition: 0.3s;
  z-index: 2;
}

.cardd:hover>.content {
  opacity: 1;
  /* transform: translateY(-100px); */
}

.cardd:hover .tittle {
  margin-top: 20px;
  transform: scale(1.2);
  transform: translate3d(50px);
}

.cardd:hover .speaker-image {
  transform: scale(1.05);
  transform: translate3d(50px);
}

.tittle {
  margin-top: 5px;
  width: 100%;
  /*font-family: "Cinzel", serif;*/
  background: -webkit-linear-gradient(#b96767, #ffffff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: white;
}

.card p {
  width: 100%;
  color: rgb(220, 220, 220);
}