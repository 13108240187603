.speaker-homepage-background {
    height: 100vh;
    width: 100%;
    background-color: black;
    text-align: center;
}

.speaker_vanta {
    position: relative;
    width: 100%;
    height: 100%;
}

.vanta-canvas {
    height: 100vh;
    width: 100%;
}

.background {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* z-index: 1; */
}

.animation div span {
    margin-left: -355px;
    animation: slidein 4s 1 forwards;
    z-index: 10;
}

.boxcontent {
    position: relative;
}

.boxcontent {
    position: relative;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 765px) {
    .mike {
        right: 0;
    }
    .logo {
        height: 100px;
        width: 285px;
        top: 35px;
    }
}

@media only screen and (width: 320px) {
    .logo {
        top: 4vh;
        height: 80px;
        width: 235px;
    }
}

.speak_heading {
    font-size: 30px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 50px;
}

.speak_heading>span {
    color: #e62b1e;
}

.speaker-title {
    border-radius: 0 50px 0 50px;
    margin: auto;
    position: absolute;
    max-width: 700px;
    top: 30vh;
    z-index: 1;
    font-family: Arial, Helvetica, sans-serif;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.35);
    /* Black w/opacity/see-through */
}

.speaker-title_head {
    text-transform: uppercase;
    color: rgb(255, 57, 57);
    font-size: 30px;
}

.speaker-title_desc {
    margin-top: 20px;
    color: #ffffff;
    font-weight: 400;
    font-size: 20px;
}

@media screen and (max-width:730px) {
    .speaker-title {
        transform: scale(0.7);
    }
}