.countdown-container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.countdown-element {
  text-align: center;
  margin: 0 1rem;
  font-size: 2rem;
  /*background-color: rgba(255,255,255,0.1);*/
  width: 25%;
  border-radius: 10px;
  font-weight: 700;
}

.countdown-element span {
  color: #eb0028;
}

.countdown-element span:first-child {
  display: block;
  font-weight: bold;
  font-size: 3rem;
}

.countdown-element span:last-child {
  font-size: 1rem;
  text-transform: uppercase;
}
