/*////////////////////////////////////////*/
/* Layout */
.panels {
    width: 100%;
    height: 25vh;
    min-height: 400px;
    overflow: hidden;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.panel {
    display: inline-block;
    height: 100%;
    position: relative;
    flex: 1;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.3, 1);
}

/*////////////////////////////////////////*/
/* Image/Text Container */
.panel__content {
    /* background-image: url(https://unsplash.it/1100/1100/?image=786); */
    height: 100%;
    /* width: 100%; */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    transition: transform 0.8s cubic-bezier(0.4, 0, 0.3, 1);
}

.panel__content:before,
.panel__content:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.8s cubic-bezier(0.4, 0, 0.3, 1);
}

.panel__content:before {
    background: inherit;
    filter: grayscale(100%);
}

.panel__content:after {
    background: #000;
    opacity: 0.3;
}

/*////////////////////////////////////////*/
/* Title */
.panel__title {
    color: #FFF;
    position: relative;
    z-index: 1;
    transition: color 0.8s cubic-bezier(0.4, 0, 0.3, 1);
    width: 100%;
    text-align: center;
    padding: 1em;
    margin: auto;
}

/*////////////////////////////////////////*/
/* Alignment */
.panel {
    overflow: hidden;
    margin-right: -15%;
}

.panel__content {
    margin-left: -15vw;
}

/*////////////////////////////////////////*/
/* Hover */
.panels {
    pointer-events: none;
}

.panel__title {
    pointer-events: auto;
}

/* Make the inactive panels darker */
.panels:hover .panel__content::after {
    opacity: 0.7;
}

/* Panels before the active panel */
.panels:hover .panel {
    transform: translate3d(-7.5vw, 0, 0);
}

/* Panels after the active panel */
.panel:hover~.panel {
    transform: translate3d(7.5vw, 0, 0);
}

/* The active panel */
.panels .panel:hover {
    pointer-events: auto;
}

.panels .panel:hover .panel__content {
    transform: translate3d(7.5vw, 0, 0);
}

.panels .panel:hover .panel__content::before,
.panels .panel:hover .panel__content::after {
    opacity: 0;
}

.panels .panel:first-child:hover {
    transform: translate3d(0, 0, 0);
}

.panels .panel:first-child:hover~.panel {
    transform: translate3d(15vw, 0, 0);
}

.panels .panel:first-child:hover .panel__content {
    transform: translate3d(7.5vw, 0, 0);
}

.panels .panel:last-child:hover .panel__content {
    transform: translate3d(3.75vw, 0, 0);
}

/*////////////////////////////////////////*/

.panel__title {
    font-size: 2.5em;
    letter-spacing: 0.1em;
}

@media (max-width: 800px) {
    .panel__title {
        font-size: 1.5em;
    }
    .panels {
    min-height: 200px;
    }
}

@media (max-width: 600px) {
    .panel__title {
        font-size: 1.25em;
    }
    .logo_title{
        transform: scale(0.5);
    }
}

@media (max-width: 400px) {
    .logo_title{
        transform: scale(0.3);
    }
}