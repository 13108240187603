.footer-outer {
	color: white;
	border-top: 1px solid rgb(68, 68, 68);
	font-size: 15px;
}
.footer-row1 {
	background-color: black;
	display: flex;
	flex-wrap: wrap;
}
.footer-col1 {
	flex: 1;
	padding: 30px;
    
}
.footer-col23 {
	flex: 1;
	display: flex;
	padding: 30px;
}
.footer-col2 {
	flex: 1;
    display: flex;
	flex-direction: column;
}
.footer-col3 {
	flex: 1;
	display: flex;
	flex-direction: column;
}
.footer-col4 {
	flex: 1;
	display: flex;
	justify-content: start;
	align-items: start;
	flex-direction: column;
}
.footer-heading {
	margin-bottom: 10px;
	font-size: 20px;
	color: red;
}
.footer-social {
	display: flex;
	font-size: 25px;
}
.footer-icon {
	color: white;
	margin: 5px;
	transition: 0.2s;
}
.footer-icon:hover {
	transform: scale(1.4);
}
.footer-email {
	color: white;
	text-decoration: none;
}
.footer-contact {
	width: fit-content;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	border: none;
	background: rgba(255, 255, 255, 0.1);
	box-shadow: 0 8px 32px 0 rgba(131, 10, 20, 0.3);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(1px);
	border-radius: 5px;
	margin-top: 10px;
}
.footer-contact-us {
	color: white;
	text-decoration: none;
	font-size: 18px;
}
.footer-row2 {
	background-color: red;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 10px;
}
.footer-ted-desc {
	text-align: justify;
}
.footer-link {
	display: inline-block;
	position: relative;
	width: fit-content;
	transition: 0.5s;
}
.footer-contact-us {
	transition: 0.25s;
}
.footer-link:hover {
	color: gray;
}
.footer-link:after {
	content: "";
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 1.5px;
	bottom: 0;
	left: 0;
	background-color: gray;
	transform-origin: bottom right;
	transition: transform 0.5s ease-out;
}
.footer-contact-us:after {
	transition: transform 0.25s ease-out;
}
.footer-link:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}
.footer-copyright,
.footer-heading {
	cursor: default;
}
.footer-copyright:hover {
	color: white;
}
.footer-copyright:after {
	background-color: white;
}
.footer-heading:after {
	background-color: red;
}
.footer-heading:hover {
	color: red;
}
@media only screen and (max-width: 960px) {
	.footer-col23 {
		flex-direction: column;
	}
	.footer-col1 {
		flex: 1.5;
	}
	.footer-col3 {
		margin-top: 20px;
	}
}
@media only screen and (max-width: 800px) {
	.footer-col1 {
		flex: 1.25;
	}
	.footer-col3 {
		margin-top: 0;
	}
}
@media only screen and (max-width: 720px) {
	.footer-outer,
	.footer-contact-us {
		font-size: 16px;
	}
}
@media only screen and (max-width: 660px) {
	.footer-row1 {
		flex-direction: column;
	}
	.footer-col23 {
		flex-direction: row;
	}
	.footer-col1 {
		padding: 50px;
		padding-bottom: 10px;
	}
	.footer-col23 {
		padding-right: 50px;
		padding-left: 50px;
	}
}
@media only screen and (max-width: 580px) {
	.footer-col23 {
		flex-direction: column;
	}
	.footer-heading {
		width: 100%;
		text-align: center;
	}
	.footer-row1 {
		justify-content: center;
		align-items: center;
	}
	.footer-social {
		justify-content: space-evenly;
	}
	.footer-contact {
		margin: auto;
	}
	.footer-col3 {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.footer-contact {
		margin-top: 10px;
	}
	.footer-heading:after {
		background-color: black;
	}
	/* .footer-heading:hover{color: black;} */
}
@media only screen and (max-width: 360px) {
	.footer-col1 {
		padding: 30px;
		padding-bottom: 10px;
	}
	.footer-col23 {
		padding-right: 30px;
		padding-left: 30px;
	}
	.footer-link {
		text-align: center;
	}
}
@media only screen and (max-width: 300px) {
	.footer-outer,
	.footer-contact-us {
		font-size: 14px;
	}
	.footer-icon {
		font-size: 20px;
	}
}

.fbicon {
	color: #1877f2;
	margin: 10px;
}
.twicon {
	color: #1da1f2;
	margin: 10px;
}

.igicon {
	color: #c32aa3;
	margin: 10px;
}

.inicon {
	color: #0a66c2;
	margin: 10px;
}
