/* theme-page */

.theme {
  width: 100vw;
  padding: 80px 25px;
  /* background-color: #191919; */
  background-color: black;
}

.theme img {
  height: auto;
  width: 420px;
}

.theme-text {
  width: 550px;
  font-size: medium;
}

.main {
  width: 1130px;
  height: 450px;
  max-width: 95%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.theme h2 {
  color: rgb(255, 42, 5);
  font-size: 60px;
  text-transform: capitalize;
  /* padding: 50px 600px; */
  margin: auto;
}

.theme-text h5 {
  color: white;
  font-size: 25px;
  text-transform: capitalize;
  margin-bottom: 25px;
  letter-spacing: 2px;
}

span {
  color: rgb(255, 42, 5);
  font-weight: normal;
}

.theme-text p {
  color: rgba(255, 255, 255, 0.705);
  font-size: 18px;
  bottom: 45px;
  letter-spacing: 1px;
  line-height: 28px;
  padding: 20px;
}

.theme_btn {
  background: rgb(255, 42, 5);
  color: white;
  text-decoration: none;
  border: 2px solid transparent;
  font-weight: bold;
  font-size: small;
  padding: 15px 30px;
  border-radius: 30px;
  transition: 0.4s;
}

.theme_btn:hover {
  background: transparent;
  border: 2px solid rgb(255, 42, 5);
  cursor: pointer;
}

@media (max-width: 950px) {
  .main {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }
  .theme {
    margin: 10px;
    padding: 40px 5px;
  }
}
@media (max-width: 600px) {
  .main {
    transform: scale(0.8);
  }
}
@media (max-width: 430px) {
  .main {
    transform: scale(0.6);
  }
}
.white {
  color: white;
}
.red {
  color: red;
}


