*,:after,:before {
    line-height: 1.5;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.outer{
    margin: 10vh auto 20vh;
    background-color: rgba(50,50,50,.15);
    padding: 3vw;
    width: 60vw;
    border: 2px solid #000;
    color: white;
}

.hading{
    text-align: center;
}
div{
    display:block;
}