.main-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 400;
  padding: 1rem 1.5rem;
  border: 0.15rem solid ivory;
  width: 100%;
  height: 10rem;
}

.date {
  border-right: 0.15rem solid ivory;
  padding-right: 1.3rem;
}

.location {
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Countdown Styles */
.countdown-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.countdown {
  display: flex;
  gap: 2rem;
}

.time-box {
  text-align: center;
}

.time-title {
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.time-value {
  font-size: 2rem;
  font-weight: bold;
  color: #de4848;
}