.button {
    display: block;
    margin: 3.125rem auto;
    overflow: hidden;
    position: relative;
    
    width: 11.25rem;
    padding: 0.75rem 0;
    border-radius: 1.875rem;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold;
    border: none;
    transition: all 0.5s ease-in-out;
    z-index: 3;
    color: rgb(255, 255, 255);
    box-shadow: inset 0px 0px 3px rgb(0, 0, 0);
    background-color: rgb(255, 0, 0);
  }
  
  .button::before{
    content: '';
    display: inline-block;
    transform: translateY(50%);
    left: 0;
    position: absolute;
    width: 11.25rem;
    height: 11.25rem;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    z-index: -1;
    transition: transform ease 1s;
  }
  
  .button:hover::before {
    transform: translateY(-45%);
  }
  
  .button:hover {
    color: rgb(255, 0, 0);
    box-shadow: 0 0 10px rgb(255, 255, 255); /* Add a black shadow on hover */
    transition: color 0.1s ease;
    transition-delay:0.2s;
}