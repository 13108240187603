.form-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.form-left {
	transform: translateX(30%);
	background: rgb(179, 5, 5);
	background: linear-gradient(
		0deg,
		rgba(179, 5, 5, 1) 33%,
		rgba(255, 0, 0, 1) 91%
	);
	padding: 25px;
	border-radius: 15px;
	transition: 0.1s;
	z-index: 1;
}
.form-right {
	-webkit-border-radius: 14px;
	border-radius: 14px;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(9px);
	-webkit-backdrop-filter: blur(9px);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: fit-content;
	margin: 5%;
	height: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.1s;
	padding: 20px 100px;
}

.tedxicem h2 {
	font-size: 70px;
	display: inline;
	width: 100%;
	color: red;
	font-family: "Arimo", sans-serif;
	display: inline;
}
.form-inputs {
	margin-top: 50px;
	color: white;
	
}
.form-input {
	color: white;
	width: 100%;
	padding: 10px;
}
.lower {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.form-input-btn {
	outline: none;
	text-decoration: none;
	width: 35%;
	display: inline-block;
	margin: 10%;
	margin-bottom: 4%;
	background-color: inherit;
	font-family: "Arimo", sans-serif;
	color: red;
	border: 1px solid red;
	padding: 10px;
	border-radius: 10px;
	font-size: 20px;
}
.form-input-btn:hover {
	cursor: pointer;
	background-color: red;
	color: white;
}
.form-input {
	outline: none;
	text-decoration: none;
	border: none;
	border-bottom: red 1px solid;
	background-color: inherit;
	font-size: 20px;
	transition: 0.05s;
}
.form-input:hover {
	border-bottom: red 2px solid;
	background-color: inherit;

}
.wrapper {
	height: 300px;
}
.tedxicem h1 {
	font-size: 3em;
	color: black;
	letter-spacing: -5px;
	font-family: "Arimo", sans-serif;
}
h1.ted::after {
	content: "x";
	font-size: 0.7em;
	vertical-align: super;
}
h1.icem,
p {
	color: white;
}
p {
	font-size: 1em;
	font-family: "Roboto Condensed", sans-serif;
}
p > span {
	color: red;
}

.footer > span {
	font-family: sans-serif;
	font-weight: bold;
	font-size: 0.8em;
	margin-left: 5px;
}
.footer h1 {
	color: red;
	font-size: 2em;
	font-family: "Roboto Condensed", sans-serif;
}
h1.venue {
	color: white;
	font-size: 1em;
}

.form-input:focus {
	color: white;
	border-bottom: red 2px solid;
	background-color: inherit;
	font-size: 22px;
}
.layer h1 {
	font-family: "Arimo", sans-serif;
}
.layer {
	margin-top: 50px;
}

@media screen and (max-width:1100px) {
	.form-left {
		display: none;
	}
	.form-right {
		width: 80vw;
	}
	.form-input{
		width: 70vw;
	}
}

@media screen and (max-width:650px) {
	.tedxicem h2 {
		font-size: 40px;
	}
}



