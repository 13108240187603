.rules-outer {
    border-top: 1px solid rgb(69, 69, 69);
    background-color: black;
    padding: 40px;
    background-image: url(../../assets/images/rules-bg.webp);
    background-position: center;
    background-size: cover;
    backdrop-filter: blur(20px);
    backdrop-filter: brightness(10%);
}

.rules-container {
    background: rgba( 255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(131, 10, 20, 0.3);
    backdrop-filter: blur( 4px);
    -webkit-backdrop-filter: blur( 1px);
    border-radius: 10px;
    height: fit-content;
    width: 70vw;
    border-radius: 15px;
    padding: 20px;
    margin: auto;
}

.rules-headings {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
}

.rules-heading {
    margin: 20px;
    font-size: 25px;
    font-weight: 600;
}

.rules-descriptions {
    display: flex;
    justify-content: center;
    text-align: justify;
    align-items: center;
    letter-spacing: 0.5px;
    line-height: 30px;
}

.rules-heading {
    cursor: pointer;
    text-align: center;
}

.rules-heading {
    display: inline-block;
    position: relative;
    color: gray;
}

.rules-active {
    display: inline-block;
    position: relative;
    color: red;
}

.rules-heading:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: gray;
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
}

.rules-heading:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.rules-active:after {
    background-color: red;
}

.rules-description {
    text-align: justify;
    color: white;
    font-size: 20px;
    margin: 30px;
    margin-left: 100px;
    margin-right: 100px;
}

@media only screen and (max-width:1200px) {
    .rules-description {
        margin: 50px;
    }
}

@media only screen and (max-width:1120px) {
    .rules-heading {
        margin: 10px;
        font-size: 24px;
    }
}

@media only screen and (max-width:960px) {
    .rules-container {
        width: 80vw;
    }
    .rules-description {
        margin: 30px;
    }
}

@media only screen and (max-width:765px) {
    .rules-headings {
        justify-content: space-evenly;
    }
    .rules-heading {
        font-size: 30px;
    }
    .rules-description {
        font-size: 22px;
    }
}

@media only screen and (max-width:550px) {
    .rules-headings {
        flex-direction: column;
    }
    .rules-heading {
        width: fit-content;
        margin: auto;
        margin-top: 10px;
        font-size: 25px;
    }
    .rules-description {
        margin: 10px;
    }
    .rules-outer {
        padding: none;
    }
    .rules-outer {
        padding: 15px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media only screen and (max-width:430px) {
    .rules-description {
        font-size: 18px;
    }
    .rules-heading {
        font-size: 22px;
    }
}

@media only screen and (max-width:360px) {
    .rules-headings {
        padding: 0;
    }
    .rules-heading {
        font-size: 20px;
    }
    .rules-description {
        font-size: 15px;
    }
}

@media only screen and (max-width:335px) {
    .rules-outer {
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media only screen and (max-width:300px) {
    .rules-outer {
        padding: 5px;
        padding-top: 20px;
        padding-bottom: 20px
    }
    .rules-description {
        font-size: 15px;
    }
}